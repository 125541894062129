import { Base } from "../base";
import { Japanese } from "flatpickr/dist/l10n/ja.js";

import flatpickr from 'flatpickr';

import 'flatpickr/dist/flatpickr.min.css';

export class Admin__RoomMasterSettings extends Base {
  /**
   * Action index
   */
  common() {
    document.getElementById('admin-container').addEventListener('scroll', function () {
      for (const calendar of document.getElementsByClassName('flatpickr-calendar')) {
        calendar.classList.remove('open');
      }
    });

    // format input Number
    function formatNumberInput() {
      var numberInputs = document.querySelectorAll('.number-input');
      numberInputs.forEach((input) => {
        input.addEventListener('sl-input', (event) => {
          let value = event.target.value === null || event.target.value === undefined || event.target.value === '' ? '' : event.target.value.replace(/\D/g, ''); // Loại bỏ tất cả ký tự không phải số
          value = value.length >= 7 ? value.slice(0, 7) : value;
          var formatted = new Intl.NumberFormat('ja-JP', {
            style: 'decimal',
          }).format(value);
          event.target.value = formatted;
        });
      });
    }
    formatNumberInput();

    // format input Number Master
    function formatNumberInputMaster() {
      var numberInputs = document.querySelectorAll('.number-input-master');
      numberInputs.forEach((input) => {
        input.addEventListener('sl-input', (event) => {
          let value = event.target.value === null || event.target.value === undefined || event.target.value === '' ? '' : event.target.value.replace(/[^\d-]/g, ''); // Chỉ giữ lại số và dấu trừ
          if (value.startsWith('-')) {
            value = '-' + value.slice(1).replace(/-/g, ''); // Chỉ giữ dấu trừ ở đầu
          } else {
            value = value.replace(/-/g, ''); // Loại bỏ dấu trừ nếu không ở đầu
          }
          if (value.startsWith('-')) {
            // Số âm: giới hạn 7 ký tự (bao gồm dấu -)
            value = value.length > 7 ? value.slice(0, 7) : value;
          } else {
            // Số dương: giới hạn 6 ký tự
            value = value.length > 6 ? value.slice(0, 6) : value;
          }
          if (value === '') {
            event.target.value = '';
          } else {
            if (value.startsWith('-')) {
              if (value.slice(1) !== '') {
                event.target.value = '-' + new Intl.NumberFormat('ja-JP', {
                  style: 'decimal'
                }).format(value.slice(1));
              } else {
                event.target.value = '-';
              }
            } else {
              if (value !== '') {
                var formatted = new Intl.NumberFormat('ja-JP', {
                  style: 'decimal'
                }).format(value);
                event.target.value = formatted;
              }
            }
          }
        });
      });
    }
    formatNumberInputMaster();

    var dateIndividual = document.querySelectorAll('.dateIndividual');
    dateIndividual.forEach((element) => {
      flatpickr(element, {
        dateFormat: 'Y-m-d',
        locale: Japanese,
        minDate: `${new Date().getFullYear()}-01-01`,
      });
      element.addEventListener('change', function (e) {
        var span = element.nextElementSibling.querySelector('span');
        span.innerHTML = dayjs(e.target.value).format('YYYY/MM/DD');
        span.style.opacity = 1;
      });
      element.addEventListener('keydown', function (e) {
        if (e.key === 'Backspace' || e.key === 'Delete') {
          var date = dayjs(new Date()).format('YYYY/MM/DD');
          e.target.value = date;
          e.target.nextElementSibling.querySelector('span').innerHTML = date;
        }
      });
    });

    const btnAddDuration = document.getElementById("btn_add_price_duration");
    const btnAddSpecificDay = document.getElementById("btn_add_specific_day");
    const btnAddCancellationSetting = document.getElementById("btn_add_cancellation_setting");
    const btnAddReservationSetting = document.getElementById("btn_add_reservation_warning_settings");
    const btnAddSpecificationSetting = document.getElementById("btn_add_specification_settings");
    const btnAddOptionalSetting = document.getElementById("btn_add_optional_settings");

    btnAddDuration.onclick = function () {
      var current = new Date()
      var timestamp = current.getTime();
      var year = current.getFullYear();
      var month = current.getMonth() + 1 < 10 ? `0${current.getMonth() + 1}` : current.getMonth() + 1;
      var lastMonthDay = new Date(current.getFullYear(), current.getMonth() + 1, 0).getDate();
      var newDiv = document.createElement("div");
      newDiv.classList.add("form__row");
      newDiv.innerHTML = `
          <div class="form__col-35">
            <div class="form__control">
              <div class="form__control--label">
                期間 <span class="required">*</span>
                <input type="hidden" name="settings[price_settings][duration][${timestamp}][category]" value="room">
                <input type="hidden" name="settings[price_settings][duration][${timestamp}][price_type]" value="duration">
              </div>
              <div class="date__field">
                <div class="date__field--input">
                  <div class="form__picker">
                    <input
                      id="startYearMonth${timestamp}"
                      class="form__picker--input dateIndividual"
                      type="date"
                      name="settings[price_settings][duration][${timestamp}][start_at]"
                    />
                    <label
                      for="startYearMonth${timestamp}"
                      class="form__picker--field"
                    >
                      <span style="opacity: 0.5">${year}/${month}/01</span>
                      <sl-icon
                        name="calendar"
                        class="calendar__icon"
                      ></sl-icon>
                    </label>
                  </div>
                </div>
                <div class="date__field--space">~</div>
                <div class="date__field--input">
                  <div class="form__picker">
                    <input
                      id="endYearMonth${timestamp}"
                      class="form__picker--input dateIndividual"
                      type="date"
                      name="settings[price_settings][duration][${timestamp}][end_at]"
                    />
                    <label
                      for="endYearMonth${timestamp}"
                      class="form__picker--field"
                    >
                      <span style="opacity: 0.5">${year}/${month}/${lastMonthDay}</span>
                      <sl-icon
                        name="calendar"
                        class="calendar__icon"
                      ></sl-icon>
                    </label>
                  </div>
                </div>
              </div>
              <div style="margin-top: 10px; display: flex; align-items: center;">
                <div class="option__checkbox" style="margin-right: 20px;">
                  <label>
                    <p>ハイシーズン</p>
                    <input type="checkbox" id="peak_season_${timestamp}" name="settings[price_settings][duration][${timestamp}][is_peak_season]" onchange="toggleCheckbox(this, 'low_season_${timestamp}')">
                    <span class="option__checkmark"></span>
                  </label>
                </div>
                <div class="option__checkbox">
                  <label>
                    <p>オフシーズン</p>
                    <input type="checkbox" id="low_season_${timestamp}" name="settings[price_settings][duration][${timestamp}][is_low_season]" onchange="toggleCheckbox(this, 'peak_season_${timestamp}')">
                    <span class="option__checkmark"></span>
                  </label>
                </div>
              </div>
              <script>
                  function toggleCheckbox(checkbox, otherCheckboxId) {
                    const otherCheckbox = document.getElementById(otherCheckboxId);
                    if (checkbox.checked) {
                      otherCheckbox.checked = false;
                    }
                  }
                </script>
            </div>
          </div>
          <div class="form__col-15">
            <div class="form__control">
              <input type="hidden" name="settings[price_settings][duration][${timestamp}][price_setting_details][0][day_type]" value="weekday">
              <div class="form__control--label">
                平日 <span class="required">*</span>
              </div>
              <div class="form__control--input">
                <sl-input class="form__control--input number-input-master" size="small" maxlength="8" 
                  name="settings[price_settings][duration][${timestamp}][price_setting_details][0][price]" 
                  style="width: 100%;">
                  <sl-icon name="currency-yen" slot="prefix"></sl-icon>
                </sl-input>
              </div>
            </div>
          </div>
          <div class="form__col-15">
            <div class="form__control">
              <input type="hidden" name="settings[price_settings][duration][${timestamp}][price_setting_details][1][day_type]" value="saturday">
              <div class="form__control--label">
                土曜 <span class="required">*</span>
              </div>
              <div class="form__control--input">
                <sl-input class="form__control--input number-input-master" size="small" maxlength="8"
                  name="settings[price_settings][duration][${timestamp}][price_setting_details][1][price]"
                  style="width: 100%;">
                  <sl-icon name="currency-yen" slot="prefix"></sl-icon>
                </sl-input>
              </div>
            </div>
          </div>
          <div class="form__col-15">
            <div class="form__control">
              <input type="hidden" name="settings[price_settings][duration][${timestamp}][price_setting_details][3][day_type]" value="sunday">
              <div class="form__control--label">
                日曜 <span class="required">*</span>
              </div>
              <div class="form__control--input">
                <sl-input class="form__control--input number-input-master" size="small" maxlength="8"
                  name="settings[price_settings][duration][${timestamp}][price_setting_details][3][price]"
                  style="width: 100%;">
                  <sl-icon name="currency-yen" slot="prefix"></sl-icon>
                </sl-input>
              </div>
            </div>
          </div>
          <div class="form__col-15">
            <div class="form__control">
              <input type="hidden" name="settings[price_settings][duration][${timestamp}][price_setting_details][2][day_type]" value="holiday">
              <div class="form__control--label">
                祝日 <span class="required">*</span>
              </div>
              <div class="form__control--input">
                <sl-input class="form__control--input number-input-master" size="small" maxlength="8"
                  name="settings[price_settings][duration][${timestamp}][price_setting_details][2][price]"
                  style="width: 100%;">
                  <sl-icon name="currency-yen" slot="prefix"></sl-icon>
                </sl-input>
              </div>
            </div>
          </div>
          
          <div class="form__col-5">
          <a style="color: #0d6efd; cursor: pointer; position: relative; top: 30px;" 
              onclick="this.parentNode.parentNode.remove()" >
              削除
            </a>
          </div>
        `;

      var parentDev = document.getElementById("item_price_duration");
      parentDev.appendChild(newDiv);

      var dateIndividual = document.querySelectorAll('.dateIndividual');
      dateIndividual.forEach((element) => {
        flatpickr(element, {
          dateFormat: "Y-m-d", //defaults to "F Y"
          locale: Japanese,
          minDate: `${new Date().getFullYear()}-01-01`,
        });
        element.addEventListener('change', function (e) {
          var span = element.nextElementSibling.querySelector('span');
          span.innerHTML = dayjs(e.target.value).format('YYYY/MM/DD');
          span.style.opacity = 1;
        });
        element.addEventListener('keydown', function (e) {
          if (e.key === 'Backspace' || e.key === 'Delete') {
            var date = dayjs(new Date()).format('YYYY/MM/DD');
            e.target.value = date;
            e.target.nextElementSibling.querySelector('span').innerHTML = date;
          }
        });
      })

      formatNumberInput();
      formatNumberInputMaster();
    };

    btnAddSpecificDay.onclick = function () {
      var current = new Date()
      var timestamp = current.getTime();
      var current_date_string = current.toISOString().split('T')[0]
      var newDiv = document.createElement("div");
      newDiv.classList.add("form__row");
      newDiv.innerHTML = `
        <div class="form__col-33">
          <div class="form__control">
            <input type="hidden" name="settings[price_settings][specific_day][${timestamp}][category]" value="room">
            <input type="hidden" name="settings[price_settings][specific_day][${timestamp}][price_type]" value="specific_day">
            <div class="form__picker">
              <input
                id="dateIndividual${timestamp}"
                class="form__picker--input dateIndividual"
                type="date"
                name="settings[price_settings][specific_day][${timestamp}][start_at]"
              />
              <label for="dateIndividual${timestamp}" class="form__picker--field">
                <span style='opacity: 0.5'>${current_date_string.replaceAll('-', '/')}</span>
                <sl-icon
                  name="calendar"
                  class="calendar__icon"
                ></sl-icon>
              </label>
            </div>
          </div>
        </div>
        <div class="form__col-33">
          <div class="form__control">
            <div class="form__horizontal">
              <div
                class="form__control--label"
                style="white-space: nowrap"
              >
                金額
              </div>
              <input type="hidden" name="settings[price_settings][specific_day][${timestamp}][price_setting_details][0][day_type]" value="custom">
              <sl-input
                class="form__control--input number-input"
                style="width: calc(100% - 29px)"
                maxlength="9"
                name="settings[price_settings][specific_day][${timestamp}][price_setting_details][0][price]"
              >
                <sl-icon name="currency-yen" slot="prefix"></sl-icon>
              </sl-input>
            </div>
          </div>
        </div>
        <div class="form__col-33">
          <a style="color: #0d6efd; cursor: pointer; position: relative; top: 10px;" 
            onclick="this.parentNode.parentNode.remove()" >
            削除
          </a>
        </div>
        `;

      var parentDev = document.getElementById("item__specific_day");
      parentDev.appendChild(newDiv);

      var dateIndividual = document.querySelectorAll('.dateIndividual');
      dateIndividual.forEach((element) => {
        flatpickr(element, {
          dateFormat: "Y-m-d", //defaults to "F Y"
          locale: Japanese,
          minDate: `${new Date().getFullYear()}-01-01`,
        });
        element.addEventListener('change', function (e) {
          var span = element.nextElementSibling.querySelector('span');
          span.innerHTML = dayjs(e.target.value).format('YYYY/MM/DD');
          span.style.opacity = 1;
        });
        element.addEventListener('keydown', function (e) {
          if (e.key === 'Backspace' || e.key === 'Delete') {
            var date = dayjs(new Date()).format('YYYY/MM/DD');
            e.target.value = date;
            e.target.nextElementSibling.querySelector('span').innerHTML = date;
          }
        });
      })

      formatNumberInput();
      formatNumberInputMaster();
    };

    btnAddCancellationSetting.onclick = function () {
      var timestamp = new Date().getTime();
      var newDiv = document.createElement("div");
      var arr = Array.from({ length: 30 }, (_, i) => i);
      var option_selects = arr.map((i) => `<sl-option value=${i}>${i}日</sl-option>`).join(" ");

      newDiv.classList.add("form__row");
      newDiv.innerHTML = `
        <div class="form__col-50">
          <div class="form__control">
            <div class="form__horizontal">
              <div style="margin-top: 12px; white-space: nowrap;">取消日</div>
              <input type="hidden" name="settings[cancellation_settings][refundable][${timestamp}][category]" value="room">
              <input type="hidden" name="settings[cancellation_settings][refundable][${timestamp}][cancel_type]" value="refundable">
              <div class="select__group">
                <div class="select__group--input">
                  <sl-select class="form__control--select" value="0"
                    name="settings[cancellation_settings][refundable][${timestamp}][from_day]">
                    ${option_selects}
                  </sl-select>
                </div>
                <div class="date__field--space">~</div>
                <div class="select__group--input">
                  <sl-select class="form__control--select" value="5"
                    name="settings[cancellation_settings][refundable][${timestamp}][to_day]">
                    ${option_selects}
                  </sl-select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form__col-30">
          <div class="form__control">
            <div class="form__horizontal">
              <div style="margin-top: 12px; white-space: nowrap;">取消料率</div>
              <sl-input
                class="form__control--input number-input"
                style="width: calc(100% - 64px)"
                maxlength="3"
                name="settings[cancellation_settings][refundable][${timestamp}][description]"
                placeholder="取消料率"
              >
                <sl-icon name="percent" slot="prefix"></sl-icon>
              </sl-input>
            </div>
          </div>
        </div>
        <div class="form__col-20">
          <a style="color: #0d6efd; cursor: pointer; position: relative; top: 10px;" 
            onclick="this.parentNode.parentNode.remove()" >
            削除
          </a>
        </div>
        `;

      var parentDev = document.getElementById("item__cancellation_setting");
      parentDev.appendChild(newDiv);

      formatNumberInput();
    };

    btnAddReservationSetting.onclick = function () {
      var timestamp = new Date().getTime();
      var newDiv = document.createElement("div");

      newDiv.classList.add("form__card");
      newDiv.innerHTML = `
        <div class="form__control">
          <div class="form__control--label">
            タイトル <span class="required">*</span>
          </div>
          <sl-input class="form__control--input"
            name="settings[reservation_warning_settings][${timestamp}][title]"
            placeholder="タイトル"
          ></sl-input>
        </div>
        <div class="form__control">
          <div class="form__control--label">
            説明 <span class="required">*</span>
          </div>
          <sl-textarea class="form__control--textarea"
            name="settings[reservation_warning_settings][${timestamp}][explanation]"
            placeholder="説明"
          ></sl-textarea>
        </div>
        <div class="form__card--delete">
          <a style="color: #0d6efd; cursor: pointer; position: relative; top: 10px;" 
            onclick="this.parentNode.parentNode.remove()" >
            削除
          </a>
        </div>
        `;

      var parentDev = document.getElementById("item_reservation_varning_settings");
      parentDev.appendChild(newDiv);
    };

    btnAddSpecificationSetting.onclick = function () {
      var timestamp = new Date().getTime();
      var newDiv = document.createElement("div");

      newDiv.classList.add("form__card");
      newDiv.innerHTML = `
        <div class="form__control">
          <div class="form__control--label">
            タイトル <span class="required">*</span>
          </div>
          <sl-input class="form__control--input"
            name="settings[specification_settings][${timestamp}][title]"
            placeholder="タイトル"
          ></sl-input>
        </div>
        <div class="form__control">
          <div class="form__control--label">
            説明文 <span class="required">*</span>
          </div>
          <sl-textarea class="form__control--textarea"
            name="settings[specification_settings][${timestamp}][description]"
            placeholder="説明文"
          ></sl-textarea>
        </div>
        <div class="form__card--delete">
          <a style="color: #0d6efd; cursor: pointer; position: relative; top: 10px;" 
            onclick="this.parentNode.parentNode.remove()" >
            削除
          </a>
        </div>
        `;

      var parentDev = document.getElementById("item_specification_setting");
      parentDev.appendChild(newDiv);
    };

    btnAddOptionalSetting.onclick = function () {
      var timestamp = new Date().getTime();
      var newDiv = document.createElement("div");

      newDiv.classList.add("form__card");
      newDiv.innerHTML = `
        <div class="form__control">
          <div class="form__control--label">
            タイトル <span class="required">*</span>
          </div>
          <sl-input class="form__control--input"
            name="settings[optional_settings][${timestamp}][title]"
            placeholder="タイトル"
          ></sl-input>
        </div>
        <div class="form__row">
          <div class="form__control form__col-50">
            <div class="form__control--label">
              金額 <span class="required">*</span>
            </div>
            <sl-input class="form__control--input number-input" maxlength="9"
              name="settings[optional_settings][${timestamp}][price]" %>
              <sl-icon name="currency-yen" slot="prefix"></sl-icon>
            </sl-input>
          </div>
          <div class="form__control form__col-50">
            <div class="form__control--label">
              数量 <span class="required">*</span>
            </div>
            <sl-input class="form__control--input number-input" maxlength="2"
              name="settings[optional_settings][${timestamp}][quantity]"
              value="5"
              placeholder="数量" %>
            </sl-input>
          </div>
        </div>
        <div class="form__card--delete">
          <a style="color: #0d6efd; cursor: pointer; position: relative; top: 10px;" 
            onclick="this.parentNode.parentNode.remove()" >
            削除
          </a>
        </div>
        `;

      var parentDev = document.getElementById("item_optional_setting");
      parentDev.appendChild(newDiv);

      formatNumberInput();
    };

    // Thêm xử lý cho holiday dates picker
    const holidayDatesContainer = document.getElementById('holiday_dates');
    const holidayDatesPicker = document.querySelector('.holiday_dates_icon');
    
    if (holidayDatesContainer && holidayDatesPicker) {
      // Khởi tạo flatpickr
      const picker = flatpickr(holidayDatesPicker, {
        dateFormat: "Y/m/d",
        locale: Japanese,
        minDate: "today",
        inline: false,
        mode: "multiple",
        
        // Lấy các ngày đã chọn từ các tag hiện có
        defaultDate: Array.from(holidayDatesContainer.querySelectorAll('.datepicker__multiple--tag span'))
          .map(span => span.textContent),
          
        onChange: function(selectedDates, dateStr) {
          // Xóa tất cả tag cũ
          holidayDatesContainer.innerHTML = '';
          
          // Tạo tag mới cho mỗi ngày được chọn
          selectedDates.forEach(date => {
            const formattedDate = dayjs(date).format('YYYY/MM/DD');
            const tagHtml = `
              <div class="datepicker__multiple--tag">
                <input type="hidden" name="settings[recurring_holiday_settings][specific_day_offs][]" value="${formattedDate}">
                <span>${formattedDate}</span>
                <div class="datepicker__multiple--remove">
                  <sl-icon name="x-lg"></sl-icon>
                </div>
              </div>
            `;
            holidayDatesContainer.insertAdjacentHTML('beforeend', tagHtml);
          });
          
          // Thêm sự kiện xóa cho các nút remove mới
          addRemoveTagHandlers();
        }
      });

      // Xử lý sự kiện click vào icon calendar
      holidayDatesPicker.addEventListener('click', () => {
        picker.open();
      });

      // Hàm thêm sự kiện xóa tag
      function addRemoveTagHandlers() {
        holidayDatesContainer.querySelectorAll('.datepicker__multiple--remove').forEach(removeBtn => {
          removeBtn.addEventListener('click', (e) => {
            const tag = e.target.closest('.datepicker__multiple--tag');
            const dateToRemove = tag.querySelector('span').textContent;
            
            // Xóa ngày khỏi flatpickr
            const currentDates = picker.selectedDates;
            picker.setDate(currentDates.filter(date => 
              dayjs(date).format('YYYY/MM/DD') !== dateToRemove
            ));
            
            // Xóa tag
            tag.remove();
          });
        });
      }

      // Thêm sự kiện xóa cho các tag có sẵn
      addRemoveTagHandlers();
    }

    // Thêm xử lý cho holiday dates release picker
    const holidayDatesReleaseContainer = document.getElementById('holiday_dates_release');
    const holidayDatesReleasePicker = document.querySelector('.holiday_dates_release_icon');
    
    if (holidayDatesReleaseContainer && holidayDatesReleasePicker) {
      // Khởi tạo flatpickr cho holiday dates release
      const releasePicker = flatpickr(holidayDatesReleasePicker, {
        dateFormat: "Y/m/d",
        locale: Japanese,
        minDate: "today",
        inline: false,
        mode: "multiple",
        
        // Lấy các ngày đã chọn từ các tag hiện có
        defaultDate: Array.from(holidayDatesReleaseContainer.querySelectorAll('.datepicker__multiple--tag span'))
          .map(span => {
            return span.textContent; 
          }),
          
        onChange: function(selectedDates, dateStr) {
          // Xóa tất cả tag cũ
          holidayDatesReleaseContainer.innerHTML = '';
          
          // Tạo tag mới cho mỗi ngày được chọn
          selectedDates.forEach(date => {
            const formattedDate = dayjs(date).format('YYYY/MM/DD');
            const tagHtml = `
              <div class="datepicker__multiple--tag">
                <input type="hidden" name="settings[recurring_holiday_settings][excluding_day_offs][]" value="${formattedDate}">
                <span>${formattedDate}</span>
                <div class="datepicker__multiple--remove">
                  <sl-icon name="x-lg"></sl-icon>
                </div>
              </div>
            `;
            holidayDatesReleaseContainer.insertAdjacentHTML('beforeend', tagHtml);
          });
          
          // Thêm sự kiện xóa cho các nút remove mới
          addRemoveReleaseTagHandlers();
        }
      });

      // Xử lý sự kiện click vào icon calendar
      holidayDatesReleasePicker.addEventListener('click', () => {
        releasePicker.open();
      });

      // Hàm thêm sự kiện xóa tag
      function addRemoveReleaseTagHandlers() {
        holidayDatesReleaseContainer.querySelectorAll('.datepicker__multiple--remove').forEach(removeBtn => {
          removeBtn.addEventListener('click', (e) => {
            const tag = e.target.closest('.datepicker__multiple--tag');
            const dateToRemove = tag.querySelector('span').textContent;
            
            // Xóa ngày khỏi flatpickr
            const currentDates = releasePicker.selectedDates;
            releasePicker.setDate(currentDates.filter(date => 
              dayjs(date).format('YYYY/MM/DD') !== dateToRemove
            ));
            
            // Xóa tag
            tag.remove();
          });
        });
      }

      // Thêm sự kiện xóa cho các tag có sẵn
      addRemoveReleaseTagHandlers();
    }
  };

  index() {
    this.common()
  }

  update_multiple() {
    this.common()
  }
}
